<template>
  <div class="home-page-container">
    <el-container class="home-container">
      <v-aside />
      <el-container class="in-container page-content-margin-sm-and-up">

        <el-main class="scroll-content">
          <el-header>
            <v-header />
          </el-header>
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import VHeader from '@/components/v-header'
import VAside from '@/components/v-aside'
import { mapState, mapActions } from 'vuex'
import MixinAllOptions from '@/mixins/all-options'
// import { getAllOptions } from '@/api/common'
export default {
  name: 'HomeContainer',
  components: {
    VHeader,
    VAside
  },
  mixins: [MixinAllOptions],
  data() {
    return {}
  },
  computed: {
    ...mapState('common', ['globalOptions'])
  },
  created() {
    // 獲取 options
    // if(!this.allOptions) {
    //   this.network().getAllOptions()
    // }
    // if(!this.globalOptions) {
    //   this.updateGlobalOptions()
    // }
  },
  methods: {
    // ...mapActions('common', ['setAllOptions', 'updateGlobalOptions']),
    // network() {
    //   return {
    //     getAllOptions: async () => {
    //       const { data } = await getAllOptions()
    //       console.log(data)
    //       this.setAllOptions(data)
    //       sessionStorage.setItem('ALL_OPTIONS', JSON.stringify(data))
    //     }
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.home-page-container {
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  background:rgb(247,250,252);
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
  .home-container{
    width: 100%;
    height: 100%;
  }
  .in-container {
    width: 100%;
    //height: calc(100% - 60px);
  }
  .el-header {
    padding: 0;
    z-index: 10;
    //width: 264px!important;
    height: auto!important;
  }
  .scroll-content{
    height: 100vh;
    -webkit-overflow-scrolling: touch;
  }

}
</style>