import axios from '@/jslib/axios'



export const login = data => {
  return axios({
    method: 'post',
    url: '/admin/login',
    data
  })
}

export const logout = params => {
  return axios({
    method: 'get',
    url: '/admin/logout',
    params
  })
}