<!--菜单栏-->
<template>
  <div
    class="aisde-page position-sm-and-down"
    :class="getClassShow">
    <el-row>
      <div>
        <el-col
          :xs="24"
          :sm="24"
          :md="16"
          :lg="16"
          :xl="17"
        >
          <div>
            <div class="hidden-sm-and-down-slide">
              <div class="header-left">
                <div class="header-logo">
                  <div
                    class="logo-container">
                    <!--                    <h2 class="theme-blue">R&B</h2>-->
                    <img
                      src="../assets/images/randb.svg"
                      alt=""
                      @click="handlerHref">
                  </div>
                </div>
                <!--                <div class="">-->
                <!--                  <span class="user">R&B Insurance Agency<br>Company Limited </span>-->
                <!--                </div>-->
              </div>
              <div class=" header-search">
                <span>Search Policy</span>
                <v-radio-group
                  v-model.number="search_type"
                  border
                  button
                  :list="searchTypeOptions"></v-radio-group>
                <v-radio-group
                  v-model.number="search_type"
                  class="margin-top-egiht"
                  border
                  button
                  :list="searchSecondTypeOptions"></v-radio-group>
                <div
                  class="flex search-flex"
                >
                  <!--                  <span-->
                  <!--                    class="flex-icon"-->
                  <!--                    @click="handlerClean">-->
                  <!--                    <i class="el-icon-arrow-left"></i>-->
                  <!--                  </span>-->
                  <v-input
                    v-model="search_content"
                    :suffix="'el-icon-search'"
                    :delete-icon="'el-icon-circle-close'"
                    @keyEnter="hanlderSearch"
                    @hanlderSearch="hanlderSearch"
                    @handlerChange="hanlderChange"
                  ></v-input>
                </div>
              </div>
            </div>
            <!--            小屏幕的时候显示-->
            <div class="is-hidden-show-mobile">

              <div class="button-theme header-search-device search_input">
                <div class="flex close-icon">
                  <i
                    class="el-icon-circle-close"
                    @click="handlerClose"></i>
                </div>
                <span>Search Policy</span>
                <v-input
                  v-model="search_content"
                  placeholder="Phone / Name / Policy"
                  :suffix="'el-icon-search'"
                  @hanlderSearch="hanlderSearch"></v-input>
              </div>
            </div>
            <el-aside
              class="aside-container-el"
              width="264">
              <el-menu :default-openeds="openeds">
                <el-submenu
                  v-for="(item, index) in menuList"
                  :key="index"
                  :index="'open'+index">
                  <template slot="title">{{ item.title }}</template>
                  <el-menu-item
                    v-for="(inItem, inIndex) in item.children"
                    :key="inIndex"
                    :index="index+'-'+inIndex"
                    @click="routerTo(inItem.path,inItem.isLogout)">
                    <router-link
                      :to="inItem.path"
                      class="in-title theme-blue">
                      <font-awesome-icon
                        v-if="inItem.icon"
                        :icon="inItem.icon"/>
                      {{ inItem.title }}
                    </router-link>
                  </el-menu-item>
                </el-submenu>
              </el-menu>
            </el-aside>
          </div>
        </el-col>
      </div>
    </el-row>
  </div>
</template>
<script>
import {mapState, mapActions} from 'vuex'
import {logout} from '@/api/account'
import MixinOptions from '@/mixins/options'
export default {
  name: 'VAside',
  mixins: [MixinOptions],
  data() {
    return {
      search_type:1,
      search_content:'',
      menuview: '',
      iconview: ['el-icon-s-unfold', 'hidden-md-and-up'],
      //展开菜单栏
      openeds: ['open0', 'open1','open2', 'open3', 'open4', 'open5', 'open6'],
      menuList: [
        {
          title: 'Quotation',
          open: true,
          children: [
            {
              title: 'Create New Quotation',
              path: '/quotation/createQuoteHome',
              icon: 'folder-open'
            }
          ]
        },
        {
          title: 'Policy',
          open: true,
          children: [
            {
              title: 'All Policy',
              path: '/policy/list',
              icon: 'file-powerpoint'
            },
            {
              title: 'Draft Policy',
              path: '/policy/draft',
              icon: 'sticky-note'
            },
          ]
        },
        {
          title: 'Renewal',
          open: false,
          children: [
            {
              title: 'Upcoming Renew',
              path: '/renewal/pending',
              icon: 'clock'
            },
            {
              title: 'Pending Renew Quote',
              path: '/renewal/Renew',
              icon: 'paste'
            },
            // {
            //   title: 'Paid & Issued',
            //   path: '/renewal/paid',
            //   icon: 'clock'
            // },
            // {
            //   title: 'Expired',
            //   path: '/renewal/expired',
            //   icon: 'file-excel'
            // }
          ]
        },

        {
          title: 'Member',
          open: true,
          children: [
            {
              title: 'Referrers',
              path: '/member/memberReferral',
              icon: 'user-friends'
            },
            {
              title: 'Insurers',
              path: '/member/insurer',
              icon: 'hotel'
            },
            // {
            //   title: 'Client- Pending Refund',
            //   path: '/member/clients',
            //   icon: 'user-friends'
            // },
          ]
        },
        {
          title: 'Reports',
          open: true,
          children: [
            // {
            //   title: 'Dashboard',
            //   path: '/account',
            //   icon: 'chart-line'
            // },
            {
              title: 'Transaction Details',
              path: '/account/transaction',
              icon: 'hand-holding-usd'
            },
            // {
            //   title: 'Referral',
            //   path: '/account/referral',
            //   icon: 'user-friends'
            // },
          ]
        },
        {
          title: 'Settings',
          open: true,
          children: [
            {
              title: 'Car Make',//'Logout',
              path: '/car/make/list',
              icon: 'motorcycle',
            }
          ]
        },
        {
          title: 'System',
          open: true,
          children: [
            {
              title: 'Logout',
              path: '/',
              icon: 'sign-out-alt',
              isLogout: true
            }
          ]
        },
      ]
    }
  },
  computed: {
    ...mapState('headerClassChange', ['getClassShow']),
    ...mapState('policy', ['search']),
  },
  watch:{
    search_content:{
      immediate:true,
      handler(val){
        if(!val){
          // this.hanlderSearch()
        }
      }
    },
    search:{
      deep: true,
      immediate:true,
      handler(val){
        if(val){
          // this.search_content = ''
          let {search_content, search_type} = val || {}
          this.search_content = search_content || ''
          this.search_type = search_type || ''
          // console.log('val',val)
          // this.hanlderSearch()
        }else {


          // console.log('val===',val)
        }
      }
    }
  },
  methods: {
    ...mapActions('headerClassChange', ['setClass']),
    ...mapActions('policy', ['setSearch','setListSearchData']),
    ...mapActions('common', ['setListSearch']),
    chanView() {
      if (this.menuview != '') {
        this.iconview[0] = 'el-icon-s-fold'
        this.menuview = ''
      } else {
        this.iconview[0] = 'el-icon-s-unfold'
        this.menuview = 'hidden-sm-and-down'
      }
    },
    handlerClose(){
      this.setClass('hidden-sm-and-down-slide')
    },
    routerTo(path, logout) {
      this.setClass('hidden-sm-and-down-slide')
      this.setListSearchData({})
      this.setListSearch({})
      if (logout)
        this.handlerLogout()
      else
        this.$router.push(path)
    },
    handlerLogout() {
      this.network().logout()
    },
    handlerClean(){
      this.search_content = ''
      // this.search_type = ''
      this.setSearch(null)
    },
    handlerHref(){
      this.$router.push('/quotation/createQuoteHome')
    },
    hanlderChange(val){
      if(!val)
      {
        this.search_content=''
        this.hanlderSearch()
      }
    },
    hanlderSearch(){
      let params = {
        search_content:this.search_content,
        search_type:this.search_type
      }
      this.setSearch(params)
      this.$router.push('/policy/list')
      this.setClass('hidden-sm-and-down-slide')
      // this.search_content = ''
    },
    network() {
      return {
        logout: async () => {
          try {
            await logout()
          } catch (e) {
            console.log(e)
          }

          localStorage.removeItem('token')
          localStorage.removeItem('auth')
          this.$router.replace('/login')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.aisde-page {
  background: #fff;
  height: 100vh;
  .margin-top-egiht{
    margin-top: 8px;
  }
  .el-aside {
    margin: 0;
    width: 250px ;
  }

  .el-row {

  }

  .header-left {
    background: white;
    width: 250px !important;
    padding: 10px 20px;
    border-right: solid 1px #e6e6e6;
    .header-logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 250px !important;
      .name {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        color: #6B7280;
      }

      .name-container {
        display: flex;
        align-items: center;
      }
    }
  }

  .header-search {
    width: 250px !important;
    padding: 10px 20px;
    background: #fff;
    border-right: solid 1px #e6e6e6;
    span {
      margin-bottom: 8px;
      display: block;
      font-size: 14px;
      font-weight: 700;
    }
    ::v-deep .el-radio-group{
      display: flex;
      .el-radio-button__inner{
        padding: 7px 0;
        width: 68px;
        background: #EDF2F7;
        box-shadow:none!important;
        //border-color: #076081!important;
      }
      .el-radio-button__orig-radio:checked+.el-radio-button__inner{
        border-color: #076081!important;
        box-shadow:none!important;
      }

    }

    .el-input {
      width: 204px;
      //margin-right: 15px;
      margin-top: 10px;
    }
  }
  .close-icon{
    background: #076081;
    justify-content: flex-end;
      i{
        color: #fff;
        font-size: 28px;
      }

  }

  .header-search-device {
    span {
      margin-bottom: 8px;
      display: block;
      font-size: 14px;
      font-weight: 700;
    }
    width: 100% ;
    padding: 10px 20px;
  }

  .aside-container-el {
    height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: solid 1px #e6e6e6;
    ::v-deep .el-menu {
      border: 0;
      background-color: #fff !important;
    }

    ::v-deep .el-submenu__title {
      color: #666;
      font-size: 12px;
      font-weight: bold;
      height: 30px;
      line-height: 30px;

      i {
        display: none;
      }

    }

    ::v-deep .el-menu-item {
      height: 30px;
      line-height: 30px;
      font-weight: 500;
      color: #076081;
      font-size: 13px;
      white-space: nowrap;
      padding: 0 20px 0 15px !important;

      * {
        vertical-align: inherit !important;
      }

      .svg-inline--fa {
        width: 25px;
        font-size: 12px;
      }

      &.is-active {
        background: #E1EFFF;
      }
    }

  }
  .in-title{
    font-weight: 500;
    color: #076081;
    font-size: 13px;
    text-decoration:none;
  }
  .side-position {
    position: absolute;
    z-index: 10;
  }
  .search-flex{
    align-items: center;
    .flex-icon{
        height: 32px;
        line-height: 32px;
    }
    span{
      margin-bottom: 0;
      i{
        color: #076081!important;

      }
    }
  }
}
</style>
